export const {
  baseAPI,
  STRIPE_KEY,
  GTM,
  MIXPANEL_TOKEN,
  MAINTENANCE,
  AWS_COGNITO_DOMAIN,
  AWS_COGNITO_CLIENT_ID,
  AWS_REDIRECT_URI_LOGIN,
  AWS_REDIRECT_URI_LOGOUT,
  AWS_REDIRECT_URI_SIGNUP,
  ENABLE_PIGEON_IN_HOUSE,
  ENABLE_SOCIAL_LOGIN,
  ENABLE_TELEMATIC_EXPEDIENTS,
  SHOW_PROFILE_IMAGE,
  USERS_ASSUMPTION_ENABLED,
  ENABLE_DASHBOARDSTATS,
  ENABLE_CALENDAR,
  ENABLE_PIECHART,
  BLOCK_ADMIN_EDIT_PRICE,
  EXPORT_LIMIT,
  ON_BOARD_CLIENTS,
  ENABLE_PIGEONAI,
  ONBOARD_BASE_PRICE,
  TWILIO_DOMAINS,
  CALL_TIMEOUT_MS,
  CALL_RETRY_MAX,
  CALL_RETRY_DELAY_MS,
} = {
  baseAPI: process.env.REACT_APP_BASE_API || 'http://localhost:3000',
  STRIPE_KEY:
    process.env.REACT_APP_STRIPE_KEY ||
    'pk_test_i0J1my6uIS8WYN6M6xlB3XDw00ARxIKz8y',
  GTM: process.env.REACT_APP_GTM, // 'GTM-5QR9Q7G'
  MIXPANEL_TOKEN:
    process.env.REACT_APP_MIXPANEL_TOKEN || 'a2c139df35d31759e8ab5f4b77faccc4',
  MAINTENANCE: process.env.REACT_APP_MAINTENANCE || false,
  AWS_COGNITO_DOMAIN:
    process.env.REACT_APP_AWS_COGNITO_DOMAIN ||
    'https://legalpigeonsandbox.auth.eu-west-3.amazoncognito.com',
  AWS_COGNITO_CLIENT_ID:
    process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || '5oalpd3qvjmpu0b5jq5a7filu5',
  AWS_REDIRECT_URI_SIGNUP:
    process.env.REACT_APP_AWS_AWS_COGNITO_REDIRECT_URI_SIGNUP ||
    'http://localhost:3001/client-signup',
  AWS_REDIRECT_URI_LOGIN:
    process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_LOGIN ||
    'http://localhost:3001/login',
  AWS_REDIRECT_URI_LOGOUT:
    process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_LOGOUT ||
    'http://localhost:3001/logout',
  ENABLE_SOCIAL_LOGIN: process.env.REACT_APP_ENABLE_SOCIAL_LOGIN || false,
  SHOW_PROFILE_IMAGE: process.env.REACT_APP_SHOW_PROFILE_IMAGE || false,
  ENABLE_TELEMATIC_EXPEDIENTS:
    process.env.REACT_APP_ENABLE_TELEMATIC_EXPEDIENTS || false,
  ENABLE_PIGEON_IN_HOUSE:
    process.env.REACT_APP_ENABLE_PIGEON_IN_HOUSE === 'true',
  USERS_ASSUMPTION_ENABLED:
    process.env.REACT_APP_USERS_ASSUMPTION_ENABLED?.split(',').filter(
      Boolean
    ) || [],
  BLOCK_ADMIN_EDIT_PRICE: process.env.REACT_APP_BLOCK_ADMIN_EDIT_PRICE || '',
  ENABLE_DASHBOARDSTATS: process.env.REACT_APP_ENABLE_DASHBOARDSTATS || false,
  ENABLE_CALENDAR: process.env.REACT_APP_ENABLE_CALENDAR || false,
  ENABLE_PIECHART: process.env.REACT_APP_ENABLE_PIECHART || false,
  EXPORT_LIMIT: process.env.REACT_APP_EXPORT_LIMIT || 1000,
  ON_BOARD_CLIENTS: process.env.REACT_APP_ON_BOARD_CLIENTS || '',
  ENABLE_PIGEONAI: process.env.REACT_APP_ENABLE_PIGEONAI === 'true',
  ONBOARD_BASE_PRICE: process.env.REACT_APP_ONBOARD_BASE_PRICE || 100,
  TWILIO_DOMAINS: process.env.REACT_APP_TWILIO_DOMAINS
    ? process.env.REACT_APP_TWILIO_DOMAINS.split(',')
    : [
        'chunderw-vpc-gll.twilio.com',
        'chunderw-vpc-gll-us1.twilio.com',
        'status.twilio.com',
      ],
  CALL_TIMEOUT_MS: parseInt(
    process.env.REACT_APP_CALL_TIMEOUT_MS || '30000',
    10
  ),
  CALL_RETRY_MAX: parseInt(process.env.REACT_APP_CALL_RETRY_MAX || '3', 10),
  CALL_RETRY_DELAY_MS: parseInt(
    process.env.REACT_APP_CALL_RETRY_DELAY_MS || '5000',
    10
  ),
}
